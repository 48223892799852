






























import ImportConfigFromNetwork from "@/components/chat/ChatExtra/ImportConfig/components/ImportConfigFromNetwork.vue";
import ImportConfigFromChat from "@/components/chat/ChatExtra/ImportConfig/components/ImportConfigFromChat.vue";

import PiTabs from "piramis-base-components/src/components/PiTabs.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, VModel } from "vue-property-decorator";
import Vue from "vue";

const enum ImportTabsEnum {
  'File' = 'File',
  'Network' = 'Network'
}

@Component({
  components: {
    ImportConfigFromChat,
    ImportConfigFromNetwork,
    PiTabs,
  },
})
export default class ImportConfigs extends Vue {
  @VModel() isModalActive!: boolean

  importConfigLoading = false

  currentTab: ImportTabsEnum = ImportTabsEnum.File

  get importTabs(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('import_config_tab_file').toString(),
        value: ImportTabsEnum.File,
        icon: {
          name: 'attach_file'
        }
      },
      {
        label: this.$t('import_config_tab_network').toString(),
        value: ImportTabsEnum.Network,
        icon: {
          name: 'icon-database',
          iconPack: 'feather'
        }
      },
    ]
  }

  afterModalClose() {
    this.currentTab = ImportTabsEnum.File
  }
}
