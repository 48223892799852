import { render, staticRenderFns } from "./setInterval.vue?vue&type=template&id=aff8f972&scoped=true&"
import script from "./setInterval.vue?vue&type=script&lang=ts&"
export * from "./setInterval.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aff8f972",
  null
  
)

export default component.exports