var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"import-config-from-network"},[_c('network-name-input',{staticClass:"mt-0 mb-0",attrs:{"prefix":"import_config_network_"},model:{value:(_vm.model.network),callback:function ($$v) {_vm.$set(_vm.model, "network", $$v)},expression:"model.network"}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'import_types',
        'prefix': 'chat_config_network_',
        'multiple': true,
        'options': _vm.networkConfigImportTypes,
        'validation': 'required'
      },
    }}}),_c('div',{staticClass:"sm:text-right mt-5"},[_c('a-button',{attrs:{"type":"primary","icon":"cloud-upload","disabled":!_vm.model.network.length || !_vm.model.import_types.length},on:{"click":_vm.applyNetworkConfigToGroupVm}},[_vm._v(" "+_vm._s(_vm.$t('upload_configs'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }