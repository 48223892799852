



















































































import Api from "@/includes/logic/Api";
import SetInterval from "./components/setInterval.vue"
import RemoveUsersRequest from "./components/RemoveUsersRequest.vue";
import { Interval } from "@/components/chat/ChatExtra/RemoveForPeriod/types";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { errorNotification } from '@/includes/NotificationService'

import Tags from "piramis-base-components/src/components/Tags/Tags.vue";
import { UseFields } from "piramis-base-components/src/components/Pi";

import { Component, Mixins, Watch } from "vue-property-decorator";
import moment from "moment";

@Component({
  components: {
    RemoveUsersRequest,
    SetInterval,
    Tags,
  },
})
export default class RemoveForPeriod extends Mixins<UseFields, TariffsTagsHelper>(UseFields, TariffsTagsHelper) {
  interval = {
    from: {
      time: '',
      date: ''
    },
    to: {
      time: '',
      date: ''
    }
  }

  step = 1

  count = null
  processed = null
  code = null

  clearUsersEstablished = false
  process = false
  activePopup = false
  popupTemplate = 0

  loading = false
  error = false

  removeButtonLoading = false

  timeoutId = -1

  get fromDateTimeString() {
    return `${ this.interval.from.date } ${ this.interval.from.time }`
  }

  get toDateTimeString() {
    return `${ this.interval.to.date } ${ this.interval.to.time }`
  }

  formatDateTime(date: string, time: string) {
    return moment(`${ date } ${ time }`).format('LLL')
  }

  @Watch('loading')
  loadingWatcher(bool: boolean): void {
    if (bool) {
      this.removeButtonLoading = true
    }
  }

  @Watch('activePopup')
  popupWatcher(bool: boolean) {
    if (!bool && (!this.code || !this.count)) {
      this.interval.from.date = moment().format('YYYY-MM-DD').split('/').join('-')
      this.interval.from.time = '12:00:00'

      this.interval.to.date = moment().add(1, 'days',).format('YYYY-MM-DD').split('/').join('-')
      this.interval.to.time = '12:00:00'

      if (this.step !== 1) {
        Api.clearUsers('tg', { chat_id: this.chatId, action: 'delete', code: this.code })
      }

      this.step = 1
      this.popupTemplate += 1
    }
  }

  get chatId(): number {
    return this.$store.state.chatState.chat.chat_id
  }

  stopRemovingUsers() {
    this.error = false

    Api.clearUsers('tg', { action: 'delete', chat_id: this.chatId, code: this.code })
      .then(data => {
        this.process = false
        this.clearUsersEstablished = false
        this.processed = null

        this.interval.from.date = moment().format('YYYY-MM-DD').split('/').join('-')
        this.interval.from.time = '12:00:00'

        this.interval.to.date = moment().add(1, 'days').format('YYYY-MM-DD').split('/').join('-')
        this.interval.to.time = '12:00:00'

        clearTimeout(this.timeoutId)
      })
  }

  setInterval(modelCopy: Interval): void {
    const from = moment(`${ modelCopy.from.date } ${ modelCopy.from.time }`)
    const to = moment(`${ modelCopy.to.date } ${ modelCopy.to.time }`)

    if (from.isAfter(to)) {
      errorNotification(this.$t('remove_for_the_period_incorrect_period').toString())
    } else if (from.isBefore(moment().add(-14, 'day'))) {
      errorNotification(this.$t('remove_for_the_period_to_early', [ moment().add(-14, 'day').format('LLL') ]).toString())
    } else {
      this.interval = { ...modelCopy }

      Api.clearUsers('tg', { chat_id: this.chatId, action: 'create', from: this.fromDateTimeString, to: this.toDateTimeString })
        .then(response => {
          this.count = response.data.data.count
          this.step = 2
        })
    }
  }

  createRequest(): void {
    this.clearUsersEstablished = true

    Api.clearUsers('tg', {
      chat_id: this.chatId,
      action: 'create',
      from: this.fromDateTimeString,
      to: this.toDateTimeString,
      count: this.count
    })
      .then(response => {
        this.code = response.data.data.code
        this.activePopup = false
        this.step = 1
      })
      .catch((error) => {
        errorNotification(error)
        this.error = true
      })
  }

  stepBack(): void {
    this.stopRemovingUsers()
    this.step = 1
  }

  check(): void {
    Api.clearUsers('tg', { chat_id: this.chatId, action: 'get' })
  }

  goRemove(): void {
    this.loading = true

    Api.clearUsers('tg', {
      chat_id: this.chatId,
      action: 'create',
      from: this.fromDateTimeString,
      to: this.toDateTimeString,
      count: this.count,
      code: this.code
    })
      .then(response => {
        if (this.clearUsersEstablished) {
          this.process = true
          this.logRemove()
          this.loading = false
        }
      })
      .finally(() => {
        this.loading = false
      })
  }

  destroyed() {
    clearTimeout(this.timeoutId)
  }

  logRemove() {
    if (this.process) {
      this.timeoutId = setTimeout(() => {
        Api.clearUsers('tg', {
          chat_id: this.chatId,
          action: 'get'
        })
          .then(({ data }) => {
            if (data.data) {
              this.processed = data.data.processed
              this.count = data.data.count

              if (this.count !== this.processed) {
                this.logRemove()
              } else {
                clearTimeout(this.timeoutId)
              }
            }
          })
      },3000)
    }
  }

  created(): void {
    if (this.$store.getters.isChatAtLeastAdvanced && this.$store.getters.isChatLicenseExists) {
      Api.clearUsers('tg', {
        chat_id: this.chatId,
        action: 'get'
      })
        .then(({ data }) => {
          if (data.data) {
            const [ fDate, fTime ] = data.data.from.split(' ')
            const [ tDate, tTime ] = data.data.to.split(' ')

            this.interval.from.date = fDate
            this.interval.from.time = fTime

            this.interval.to.date = tDate
            this.interval.to.time = tTime

            this.clearUsersEstablished = true

            this.count = data.data.count
            this.code = data.data.code

            if (data.data.processed !== -1) {
              this.process = true
              this.logRemove()
            }
          } else {
            this.interval.from.date = moment().format('YYYY-MM-DD').split('/').join('-')
            this.interval.from.time = '12:00:00'
            this.interval.to.date = moment().add(1, 'days').format('YYYY-MM-DD').split('/').join('-')
            this.interval.to.time = '12:00:00'
          }
        })
    }
  }
}
