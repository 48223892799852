var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"transfer-license-wrapper"},[(_vm.isMounted)?_c('div',{staticClass:"transfer-license"},[_c('a-alert',{attrs:{"show-icon":"","message":_vm.$t('transfer_license_info_message')}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'target_id',
            'options': _vm.getChatOptions,
            'clearable': false,
            'validation': 'required',
          }
        }}}),(_vm.showAlert)?_c('a-alert',{attrs:{"show-icon":"","type":"error","message":_vm.$t('transfer_license_choice_license')}}):_vm._e(),_c('div',{staticClass:" mt-5 w-full flex flex-col md:flex-row justify-around gap-2"},_vm._l((_vm.chatsTariffs),function(tariff,index){return _c('div',{key:index,staticClass:"flex items-center justify-center hover:cursor-pointer px-4 py-2 rounded-md hover:opacity-75",style:({
            backgroundColor: _vm.currentTariff === tariff ? Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][1].color : '',
            border: '1px solid',
            borderColor: Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][1].color
          }),on:{"click":function($event){_vm.currentTariff = _vm.chatsTariffs[index]}}},[_c('img',{staticStyle:{"width":"50px"},attrs:{"src":Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][1].img}}),_c('div',{staticClass:"h-full flex items-center"},[_c('span',{staticClass:"font-bold text-lg capitalize",class:[_vm.currentTariff === tariff ? 'text-white' : 'text-primary']},[_vm._v(" "+_vm._s(Object.entries(_vm.$store.state.themeConfig.config.tariffs)[tariff][0])+" ")])])])}),0),_c('div',{staticClass:"mt-5 px-2 flex flex-col gap-2 md:flex-row md:justify-end"},[_c('a-button',{staticClass:"btn-wrap",attrs:{"type":"","disabled":!_vm.target_id},on:{"click":_vm.transferLicense}},[_vm._v(" "+_vm._s(_vm.$t('transfer_license_button_transfer'))+" ")]),_c('a-button',{staticClass:"btn-wrap",attrs:{"type":"danger"},on:{"click":function($event){return _vm.$emit('transfer')}}},[_vm._v(" "+_vm._s(_vm.$t('transfer_license_button_cancel'))+" ")])],1)],1):_c('a-spin',{staticClass:"w-full text-center"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }