import Api from "@/includes/logic/Api";
import { SendMessageReq, SendMessageRes } from "@/includes/types/SendMessage";

export default class SendMessageApi {
  static sendSelfMessage({ chat_id, message }: SendMessageReq): Promise<{ data: SendMessageRes }> {
    return Api.sendRequest('tg', "sendselfmessage", { chat_id,
      message });
  }

  static sendChatMessage({ chat_id, message }: SendMessageReq): Promise<{ data: SendMessageRes }> {
    return Api.sendRequest('tg', "sendchatmessage", {
      chat_id,
      message,
    });
  }
}
