







































import Api from "../../../includes/logic/Api";
import { InputSetups } from "@/mixins/input-setups"
import TriggersMixin from '@/mixins/TriggersMixin'

import { UseFields } from "piramis-base-components/src/components/Pi/index"

import { Component, Mixins } from 'vue-property-decorator'

@Component({

})
export default class ExportConfigs extends Mixins(UseFields, InputSetups, TriggersMixin) {
  export_chat_config = false

  export_triggers: Array<string> = []

  exportSettings(): void {
    const params = {
      chat_id: this.$store.state.chatState.chat.chat_id,
      triggers: this.export_triggers.length > 0 ? this.export_triggers : null
    }

    if (!this.export_chat_config) {
      Api.exportChatTriggers('tg', params)
    } else {
      Api.exportChatConfig('tg', params)
    }

    this.$emit('export')
  }
}
