import { BaseChatInfo } from "@/includes/types/Chat/types";
import { BaseNetworkData } from "@/includes/types/networks";

export const enum NetworkImportTypeEnum {
  Modules = 'Modules',
  Triggers = 'Triggers'
}

export type NetworkConfigImport = {
  network: BaseNetworkData['name'],
  chat_id: BaseChatInfo['chat_id'],
  import_types: Array<NetworkImportTypeEnum>
}
