











































import Vue from 'vue'
import { Component, Emit, Prop } from "vue-property-decorator";
import { Interval } from "../types";
import moment from 'moment'

@Component
export default class RemoveUsersRequest extends Vue {
  @Prop() interval!: Interval

  @Prop() count!: number

  @Emit()
  createRequest() {

  }

  @Emit()
  stepBack() {
  }

  formatDateTime(date: string, time: string) {
    return moment(`${ date } ${ time }`).format('LLL')
  }
}
